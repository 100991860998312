import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";

import styles from "./index.module.scss";
import collapsible from "components/shared/collapsible/index.module.scss";
import { useMemo } from "react";
import { useAppSelector } from "hooks";
import { selectAssignmentSelected } from "slices/assignment/assignmentSelectedSlice";
import BreadcrumbComponent from "components/shared/breadcrumb";
import { selectRequestItemsDetails } from "slices/assignment/requestItemsDetailsSlice";
import { RequestItemsDetails } from "dto/requestItemsDetails";
import Modal from "components/shared/modal";
import { isEmailValid } from "components/utils/inputValidation/email";
import { inviteContact } from "components/api/valuation/inviteToCollaborate";
import { selectIdentity } from "slices/user/identitySlice";
import { toast } from "react-toastify";
import { MatomoActions, MatomoEventCategories, trackEvent } from "matomo";
import { getTimezone } from "components/utils/datetime/datetime";

const parseDueDate = (isoDateString: string) => {
  // Given a job due date from Ngage, return individual day,
  // month, and year. Include a test to handle instances where
  // the date is not defined or incorrectly defined;
  const date = new Date(isoDateString);
  const isValidDate = !isNaN(date.valueOf());

  let dueDay: string = "",
    dueMonth: string = "",
    dueYear: string = "";

  if (isValidDate) {
    dueDay = date.toLocaleString("en-US", { day: "numeric" });
    dueMonth = date.toLocaleString("en-US", { month: "long" });
    dueYear = date.toLocaleString("en-US", { year: "numeric" });
  }

  return {
    get isValid() {
      return isValidDate;
    },
    get day() {
      return dueDay;
    },
    get month() {
      return dueMonth;
    },
    get year() {
      return dueYear;
    },
  };
};

function getPropertyContact(requestItemsDetails: RequestItemsDetails) {
  if (!requestItemsDetails) return { summary: "", fullContacts: [] };

  const { contactsDetail, clientContactIds } = requestItemsDetails;
  const contacts = clientContactIds.map((id) =>
    contactsDetail.find((c) => c.id === id)
  );
  const firstContact =
    contacts.length > 0 ? contacts[0]!.name || contacts[0]!.email : "";
  const plus =
    clientContactIds.length > 1 ? `${` +${clientContactIds.length - 1}`}` : "";
  return { summary: firstContact + plus, fullContacts: contacts };
}

function ValuationsDataRoomHeader() {
  const assignmentData = useAppSelector(selectAssignmentSelected);
  const requestItemsDetails = useAppSelector(selectRequestItemsDetails);
  const dueDate = useMemo(
    () => parseDueDate(assignmentData?.jobAssignmentDueDate),
    [assignmentData?.jobAssignmentDueDate]
  );
  const { summary: propertyContactSummary, fullContacts: propertyContactList } =
    getPropertyContact(requestItemsDetails);

  const [isRequestDetailsOpen, setRequestDetailsOpen] = useState(true);

  const identity = useAppSelector(selectIdentity);
  const [inviteToCollaborate, setInviteToCollaborate] = useState(false);
  const handleInviteClick = () => { setInviteToCollaborate(true); };
  const handleClose = () => { setInviteToCollaborate(false); };
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [inviteeName, setInviteeName] = useState("");
  const [inviteePhoneNumber, setInviteePhoneNumber] = useState("");

  return (
    <Container
      fluid
      className={`d-flex flex-column my-4 gap-4 ${styles["valuations-header"]}`}
    >
      {/* Breadcrumbs; */}
      <Row>
        <BreadcrumbComponent />
      </Row>

      <Row>
        <Col className="pl-0 pb-0">
          <Row>
            <Col
              xs={{ size: 12 }}
              className={`pl-0 pb-0  ${styles["col-deco"]} ${styles.group}`}
            >
              {/* Row 1: References and Due Date; */}
              <Row>
                {/* Col 1: References and Property; */}
                <Col
                  xs={{ size: 12 }}
                  // xs={{ size: 8 }}
                  // md={{ size: 9 }}
                  // xl={{ size: 10 }}
                  className="px-0"
                >
                  {/* Row 1-1: References; */}
                  <Row
                    className={`d-flex flex-column flex-xl-row gap-2 gap-xl-3 pt-4 pb-3 px-3 px-sm-4 px-xl-2 mx-0 ${styles.references}`}
                  >
                    {/* Reference: Job; */}
                    <Col
                      xs={{ size: 12 }}
                      className={`d-flex flex-wrap flex-xs-nowrap gap-1 px-0 px-xl-4 col-lg-auto text-nowrap ${styles.info__group}`}
                    >
                      <span
                        className={`fw-bold lh-1 text-uppercase  ${styles["info__group-label"]}`}
                      >
                        Newmark Job
                      </span>
                      <span className={`lh-1 ${styles["info__group-data"]}`}>
                        {!!(assignmentData?.jobId > 0)
                          ? assignmentData?.jobId
                          : "N/A"}
                      </span>
                    </Col>

                    {/* Reference: Assignment; */}
                    <Col
                      xs={{ size: 12 }}
                      className={`d-flex flex-wrap flex-xs-nowrap gap-1 px-0 col-lg-auto text-nowrap ${styles["info__group"]}`}
                    >
                      <span
                        className={`fw-bold lh-1 text-uppercase ${styles["info__group-label"]}`}
                      >
                        Assignment
                      </span>
                      <span className={`lh-1 ${styles["info__group-data"]}`}>
                        {!!assignmentData.jobAssignmentNumber
                          ? assignmentData?.jobAssignmentNumber
                          : "N/A"}
                      </span>
                    </Col>

                    {/* Reference: Client; */}
                    {assignmentData.jobAssignmentClientReference && (
                      <Col
                        xs={{ size: 12 }}
                        className={`d-flex flex-wrap flex-xs-nowrap gap-1 px-0 col-lg-auto text-nowrap ${styles.info__group}`}
                      >
                        <span
                          className={`fw-bold lh-1 text-uppercase ${styles.info__groupLabel}`}
                        >
                          Client Ref
                        </span>
                        <span className={`lh-1 ${styles.info__groupData}`}>
                          {assignmentData.jobAssignmentClientReference}
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>

                {/* Col 2: Due Date; */}
                {/* <Col
									xs={{ size: 4 }}
									md={{ size: 3 }}
									xl={{ size: 2 }}
									className="px-0"
								>
									<div
										className={`d-flex flex-column h-100 px-3 py-2 pt-sm-3 pb-sm-2 ${styles.due}`}
									>
										{/* Date; 
										<Row>
											{/* Header; 
											<Col xs={{ size: 12 }} className="pt-2 pt-sm-0">
												<h3
													className={`pt-2 fw-bold mb-0 lh-1 text-uppercase ${styles["due__header"]}`}
												>
													{dueDate.isValid ? "Upload By" : "Upload By N/A"}
												</h3>
											</Col>
										</Row>
										{dueDate.isValid && (
											<Row className="row flex-sm-nowrap px-1 px-sm-2 px-md-2 gap-0">
												{/* Day; 
												<Col
													xs={{ size: "auto" }}
													className={`lh-1 pt-2 px-0 pl-xs-2 pl-sm-0 pl-md-0 ${styles["due__day"]}`}
												>
													{dueDate.day}
												</Col>

												{/* Month / Year; 
												<Col
													xs={{ size: "auto" }}
													className={`px-1 pl-xs-1 px-sm-1 d-flex flex-column align-items-start justify-content-center ${styles["due__month-year"]}`}
												>
													<div className="lh-1">{dueDate.month}</div>
													<div className="lh-1">{dueDate.year}</div>
												</Col>
											</Row>
										)}
									</div>
								</Col> */}
              </Row>

              {/* Row 2: Property; */}
              <Row
                className={`pt-4 pb-3 pt-xs-4 pb-xs-3 pt-md-4 pb-md-4 pt-sm-4 pb-sm-2 px-2 px-xl-3 mx-0 mx-xl-1 mb-0 mb-sm-0 d-flex flex-column align-content-start justify-content-center ${styles.property}`}
              >
                <h2 className={`w-100 px-0 ${styles["property__name"]}`}>
                  {!!assignmentData?.propertyName
                    ? assignmentData?.propertyName
                    : "Not Available"}
                </h2>
                <p
                  className={`d-flex flex-wrap lh-1 px-0 ${styles["property__address-line"]}`}
                >
                  {/* Add commas after address lines and city if present in data; also use spans for spacing control; */}
                  {assignmentData?.propertyAddress1 && (
                    <>
                      <span>{assignmentData?.propertyAddress1}</span>
                      <span className={`${styles["comma-sep"]}`}></span>
                    </>
                  )}
                  {assignmentData?.propertyAddress2 && (
                    <>
                      <span>{assignmentData?.propertyAddress2}</span>
                      <span className={`${styles["comma-sep"]}`}></span>
                    </>
                  )}
                  {assignmentData?.propertyCity && (
                    <>
                      <span>{assignmentData?.propertyCity}</span>
                      <span className={`${styles["comma-sep"]}`}></span>
                    </>
                  )}
                  {assignmentData?.propertyState && (
                    <span>{assignmentData?.propertyState}</span>
                  )}
                  {assignmentData?.propertyZip && (
                    <span>{assignmentData?.propertyZip}</span>
                  )}
                  {assignmentData?.propertyCountryName && (
                    <span>{assignmentData?.propertyCountryName}</span>
                  )}
                </p>
              </Row>

              {/* Row 3: Contacts; */}
              <Row>
                {/* Contacts; */}
                <Col
                  xs={{ size: 12 }}
                  className="px-2 px-lg-0 mx-1 mx-lg-0 mb-4 mb-sm-0"
                >
                  {/* Contacts; */}
                  <Row
                    className={`d-flex flex-column flex-lg-row gap-3 gap-lg-3 py-3 py-xl-4 px-0 px-lg-0 mx-2 mx-md-2 ${styles.contacts}`}
                  >
                    {/* Contacts: Client; */}
                    <Col
                      xs={{ size: 12 }}
                      className={`d-flex flex-column gap-1 gap-lg-2 px-0 px-lg-2 px-xl-4 col-lg-auto text-nowrap ${styles.info__group}`}
                    >
                      <span
                        className={`fw-bold lh-1 text-uppercase ${styles["info__group-label"]}`}
                      >
                        Client
                      </span>
                      <span
                        className={`lh-1 text-wrap ${styles["info__group-data"]}`}
                      >
                        {!!assignmentData.companyName
                          ? assignmentData.companyName
                          : "N/A"}
                      </span>
                    </Col>

                    {/* Contacts: Property; */}
                    <Col
                      xs={{ size: 12 }}
                      className={`d-flex flex-column gap-1 gap-lg-2 px-0 px-lg-2 px-xl-4 col-lg-auto text-nowrap ${styles.info__group}`}
                    >
                      <span
                        className={`fw-bold lh-1 text-uppercase ${styles["info__group-label"]}`}
                      >
                        Property Contacts
                      </span>
                      <span
                        id="assignmentPropertyContacts"
                        className={`lh-1 text-wrap ${styles["info__group-data"]}`}
                      >
                        {propertyContactSummary}
                      </span>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="assignmentPropertyContacts"
                        trigger="hover focus"
                        className="tooltip"
                      >
                        {propertyContactList
                          .map((c) => c?.name || c?.email)
                          .join(",\n")}
                      </UncontrolledTooltip>
                    </Col>

                    {/* Contacts: Appraisers; */}
                    <Col
                      xs={{ size: 12 }}
                      className={`d-flex flex-column gap-1 px-0 px-lg-2 px-xl-4 col-lg-auto text-nowrap ${styles.info__group}`}
                    >
                      <span
                        className={`fw-bold lh-1 text-uppercase ${styles["info__group-label"]}`}
                      >
                        {assignmentData.jobAssignmentLeadAppraisers.length > 0
                          ? "Appraisers"
                          : "Appraiser"}
                      </span>
                      <span
                        id="assignmentAppraisers"
                        className={`lh-1 text-wrap ${styles["info__group-data"]}`}
                      >
                        {assignmentData.jobAssignmentLeadAppraisers.length > 0
                          ? `${assignmentData.jobAssignmentLeadAppraisers[0]}${
                              assignmentData.jobAssignmentLeadAppraisers
                                .length > 1
                                ? ` +${
                                    assignmentData.jobAssignmentLeadAppraisers
                                      .length - 1
                                  }`
                                : ""
                            }`
                          : "N/A"}
                      </span>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="assignmentAppraisers"
                        trigger="hover focus"
                        className="tooltip"
                      >
                        {assignmentData.jobAssignmentLeadAppraisers.length > 0
                          ? assignmentData.jobAssignmentLeadAppraisers.join(
                              ",\n"
                            )
                          : "No appraisers assigned"}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Row: Request Details; */}
          <Row>
            <Col
              className={`px-3 px-lg-4 pt-2 mx-0 mx-lg-0 col-12 ${
                collapsible.collapsible
              } ${isRequestDetailsOpen ? collapsible.active : ""}`}
            >
              <button
                aria-expanded={isRequestDetailsOpen}
                onClick={() => setRequestDetailsOpen(!isRequestDetailsOpen)}
                className={`toggle ${
                  isRequestDetailsOpen
                    ? `${collapsible.collapsible__toggle} ${collapsible.active}`
                    : `${collapsible.collapsible__toggle}`
                }`}
              >
                Request Details
              </button>
              <div className="invite-modal-button-separator"></div>
              <span className="action-icon-parent">
                <button
                  className="action-icon"
                  onClick={() => handleInviteClick()}
                >
                  Invite to Collaborate
                </button>
              </span>
              <div
                className={`pt-2 ${collapsible["collapsible__content-clipper"]}`}
              >
                <div className={`${collapsible.collapsible__content}`}>
                  <h4>Information about this request</h4>
                  <p>{requestItemsDetails?.requestDetailsMessage}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        className="invite-modal"
        isOpen={inviteToCollaborate}
        confirmButtonProps={{
          disabled: !inviteeEmail,
        }}
        onConfirm={async () => {
          try {
            await inviteContact(identity,
              assignmentData.jobId,
              assignmentData.jobAssignmentPropertyId,
              inviteeEmail,
              inviteeName,
              inviteePhoneNumber
            );
          } catch (e) {
            console.error(e);
            toast.error("Failed to collaborate");
            return;
          } finally {
            setInviteeEmail("");
            setInviteeName("");
            setInviteePhoneNumber("");
            handleClose();
          }

          trackEvent(
            MatomoEventCategories.Collaborate,
            MatomoActions.Collaborate,
            getTimezone()
          );
          toast.success("invited contact to collaborate");
        }}

        onClose={() => {
          setInviteeEmail("");
          setInviteeName("");
          setInviteePhoneNumber("");
          handleClose();
        }}
        contentLabel="Invite Modal"
        ariaDescription="Invite people to collaborate"
        title="Invite People"
      >
        <span className="reassign-modal-text">
          Collaborate with:
        </span>
        <div className="reassign-modal-input-parent">
          Email
          <span className="mandatory-field-color"> *</span>
          <br />
          <input
            type="text"
            className="invite-modal-input"
            onChange={(e) => {
              const email = e.target.value;
              if (isEmailValid(email, { isForValuations: true })) {
                setInviteeEmail(email);
              } else {
                setInviteeEmail("");
              }
            }}
          />
        </div>
        <div className="reassign-modal-input-parent">
          Name
          <br />
          <input
            type="text"
            className="invite-modal-input"
            onChange={(e) => setInviteeName(e.target.value)}
          />
        </div>
        <div className="invite-modal-input-parent">
          Phone Number
          <br />
          <input
            type="text"
            className="invite-modal-input"
            onChange={(e) => setInviteePhoneNumber(e.target.value)}
          />
        </div>
      </Modal>
    </Container>
  );
}

export default ValuationsDataRoomHeader;
